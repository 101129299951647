<template>
    <div id="Media">
        <b-row>
            <b-col sm="8">
                <div class="card">
                    <div slot="header" class="card-header">
                        <i class="icon-layers"></i> Medienverwaltung
                        <button type="button" class="btn btn-primary btn-sm float-right" @click="openFileUploadDialog()">
                            <span class="icon-plus"></span> Medien hinzufügen
                        </button>
                        <button v-if="authObj && authObj.rolename == 'Administrator'" type="button" class="btn btn-primary btn-sm float-right" @click="openCreateFolderDialog()">
                            <span class="icon-folder"></span> Ordner erstellen
                        </button>
                        <button v-if="mediaViewType == 'table'" type="button" @click="changeViewType('list')" class="btn btn-primary btn-sm float-right">
                            <span class="cui-list"></span> Listenansicht
                        </button>
                        <button v-if="mediaViewType == 'list'" type="button" @click="changeViewType('table')" class="btn btn-primary btn-sm float-right">
                            <span class="fa fa-table"></span> Tabellenansicht
                        </button>
                    </div>
                    <b-row>
                        <b-col sm="3" class="col-no-padding-right">
                            <div v-if="folders" style="margin-top: 20px; margin-left: 10px; padding-left: 5px;" class="card tree">
                                <div v-for="folder in folders">
                                    <FolderTree :folder="folder" :ref="folder.id" :level="1" @folderSelection="folderSelection"></FolderTree>
                                </div>
                            </div>

                        </b-col>
                        <b-col>
                            <div v-if="media" class="card-body float-left">
                                <div v-if="mediaViewType == 'table'" v-for="(medi, index) in media" @click="select(medi)" class="card" :class="[ selected !== null && medi.id === selected.id ? 'border-primary' : '' ]" style="width: 10.0rem; float: left; margin-right: 10px;">
                                    <div style="position: relative; height: 0; padding-bottom: 66.5%; cursor: pointer;">
                                        <img v-if="medi.mimeType.includes('image')" class="card-img-top" style="width: auto; height: auto; max-width: calc(100% - 10px); max-height: 90%; position:absolute; left: 50%; top:50%; transform: translate3d(-50%,-50%, 0);" :title="medi.name"  v-bind:src="$store.state.mediaServiceUrl + medi.uid + '/200/200'" v-bind:alt="medi.alt">
                                        <div v-if="medi.mimeType.includes('video')">
                                            <div style="float: left;">
                                                <img v-if="medi.mimeType.includes('youtube')" class="card-img-top" style="width: auto; height: auto; max-width: calc(100% - 10px); max-height: 90%; position:absolute; left: 50%; top:50%; transform: translate3d(-50%,-50%, 0);" :title="medi.name" src="img/icons/youtube.png" v-bind:alt="medi.alt">
                                                <img v-else class="card-img-top" style="width: auto; height: auto; max-width: calc(100% - 10px); max-height: 90%; position:absolute; left: 50%; top:50%; transform: translate3d(-50%,-50%, 0);" :title="medi.name" v-bind:src="medi.image? $store.state.mediaServiceUrl + medi.image.uid + '/200/200' : 'img/icons/multimedia.png'" v-bind:alt="medi.alt">
                                            </div>
                                            <div style="position: absolute">
                                                <p v-if="medi.mimeType.includes('youtube')" ><b-badge variant="danger">YouTube</b-badge></p>
                                                <p v-else><b-badge variant="secondary">Video</b-badge></p>
                                            </div>
                                        </div>
                                        <div v-if="medi.mimeType == 'application/pdf'">
                                            <div style="float: left;">
                                                <img  class="card-img-top" style="width: auto; height: auto; max-width: calc(100% - 10px); max-height: 90%; position:absolute; left: 50%; top:50%; transform: translate3d(-50%,-50%, 0); float: left" src="img/icons/pdf.png" :title="medi.name" v-bind:alt="medi.alt">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="mediaViewType == 'list'" v-for="medi in media" @click="select(medi)" class="file-list-row" :class="[ selected !== null && medi.id === selected.id ? 'file-list-row-selected' : '' ]">
                                    <img v-if="medi.mimeType != 'application/pdf'" class="card-img-top" style="width: 40px; height: auto; max-height: 40px;" :title="medi.name"  v-bind:src="$store.state.mediaServiceUrl + medi.uid + '/200/200'" v-bind:alt="medi.alt">
                                    <img v-if="medi.mimeType == 'application/pdf'" class="card-img-top" style="width: 40px;" src="img/icons/pdf.png" :title="medi.name" v-bind:alt="medi.alt">
                                    <span class="filename">{{medi.name}}</span>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                </div>
            </b-col>
            <b-col sm="4">
                <div v-if="selectedFolder.id > 0 && authObj &&  authObj.rolename == 'Administrator'" class="card">
                    <div slot="header" class="card-header">
                        Ordner - Informationen
                    </div>
                    <div class="card-body">
                        <b-form-group validated>
                            <b-row>
                                <b-col md="2" class="mr7">
                                    <label for="name" class="form-control-sm">Name</label>
                                </b-col>
                                <b-col>
                                    <b-form-input type="text" id="name" class="form-control-sm" required aria-required="true" v-model="selectedFolder.name" placeholder="Ordner-Name"></b-form-input>
                                </b-col>
                            </b-row>
                        </b-form-group>
                        <div>
                            <button type="button" class="btn btn-danger btn-sm" @click="warningModalDeleteFolder = true, folderToDelete = selectedFolder.id" style="float:right"><span class="icon-minus"></span> Ordner löschen</button>
                            <button type="button" class="btn btn-primary btn-sm" @click="updateFolder()" style="float:right"><span class="fa fa-save"></span> Änderungen speichern </button>
                        </div>
                    </div>
                </div>

                <div v-if="selected" class="card">
                    <div slot="header" class="card-header">
                        Datei - Informationen
                    </div>
                    <div class="card-body">
                        <div style="position: relative; heigth: 0; padding-bottom: 60.0%;">
                            <img v-if="selected.mimeType != 'application/pdf'" v-bind:src="$store.state.mediaServiceUrl + selected.uid + '/400/400'" style="width: auto; height: auto; max-width: calc(100% - 10px); max-height: 90%; position:absolute; left: 50%; top:50%; transform: translate3d(-50%,-50%, 0)">
                          <img v-if="selected.mimeType == 'application/pdf'" src="img/icons/pdf.png" style="width: auto; height: auto; max-width: calc(100% - 10px); max-height: 90%; position:absolute; left: 50%; top:50%; transform: translate3d(-50%,-50%, 0)">
                        </div>
                        <div>
                            <b-row>
                                <b-col md="2" class="mr7">
                                    <label for="mediaName" class="form-control-sm">Name</label>
                                </b-col>
                                <b-col>
                                    <b-form-input type="text" id="mediaName" class="form-control-sm" required aria-required="true" v-model="selected.name" placeholder="Name"></b-form-input>
                                </b-col>
                            </b-row>
                            <div v-if="selected.mimeType != 'application/pdf'">
                                <b-row>
                                    <b-col md="2" class="mr7">
                                        <label for="mediaAlt" class="form-control-sm">Alt</label>
                                    </b-col>
                                    <b-col>
                                        <b-form-input type="text" id="mediaAlt" class="form-control-sm" v-model="selected.alt" placeholder="Alt"></b-form-input>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col md="2" class="mr7">
                                        <label for="mediaTitle" class="form-control-sm">Title</label>
                                    </b-col>
                                    <b-col>
                                        <b-form-input type="text" id="mediaTitle" class="form-control-sm" v-model="selected.title" placeholder="Title"></b-form-input>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col md="2" class="mr7">
                                        <label for="mediaCaption" class="form-control-sm">Caption</label>
                                    </b-col>
                                    <b-col>
                                        <b-form-input type="text" id="mediaCaption" class="form-control-sm" v-model="selected.caption" placeholder="MimeType"></b-form-input>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col md="2" class="mr7">
                                        <label for="mediaBgPosX" class="form-control-sm" style="white-space: nowrap;">Pos-X:</label>
                                    </b-col>
                                    <b-col md="3">
                                        <b-form-input type="text" id="mediaBgPosX" class="form-control-sm" v-model="selected.backgroundPosX" placeholder="0"></b-form-input>
                                    </b-col>

                                    <b-col md="2" class="mr7">
                                        <label for="mediaBgPosY" class="form-control-sm" style="white-space: nowrap;">Pos-Y:</label>
                                    </b-col>
                                    <b-col md="3">
                                        <b-form-input type="text" id="mediaBgPosY" class="form-control-sm" v-model="selected.backgroundPosY" placeholder="0"></b-form-input>
                                    </b-col>
                                </b-row>
                            </div>
                        </div><br>
                        <div>
                            <button type="button" class="btn btn-danger btn-sm" @click="warningModalDeleteFile = true, mediaToDelete = selected.id" style="float:right"><span class="icon-minus"></span> Medium löschen</button>
                            <button type="button" class="btn btn-primary btn-sm" @click="updateMedia()" style="float:right"><span class="fa fa-save"></span> Änderungen speichern </button>
                        </div>
                    </div>
                </div>
            </b-col>
        </b-row>
        <!-- Modal Components -->
        <b-modal title="Medium löschen?" class="modal-warning" v-model="warningModalDeleteFile" @ok="deleteMedia()" ok-variant="warning">
            Wollen Sie die Datei wirklich löschen?
        </b-modal>
        <b-modal title="Ordner löschen?" class="modal-warning" v-model="warningModalDeleteFolder" @ok="deleteFolder()" ok-variant="warning">
            Wollen Sie den Ordner wirklich löschen?
        </b-modal>
        <b-modal title="Medien hinzufügen" v-model="uploadModal" @ok="uploadMedia()">
            <div v-if="!youtubeVideo">
                <input type="file" @change="fileInputSelected" multiple="multiple" ref="uploadFiles"><p>
                <div class="upload--box" v-cloak @drop.prevent="addFiles" @dragover.prevent>
                    <span>Hier Dateien per Drag+Drop hochladen</span>
                </div>
            </div>
            <div v-if="fileCollection.length == 0">
                <b-form-group>
                    <b-form-checkbox class="mb-2 mr-sm-2 mb-sm-0" v-model="youtubeVideo" >Ich wurde ein YouTube-VideoLink als Media hinzufúngen</b-form-checkbox>
                </b-form-group>
            </div>
            <div v-if="fileCollection.length == 1 || youtubeVideo">
                <b-form-group>
                    <label for="mediaName">Name</label>
                    <b-form-input type="text" id="mediaName" v-model="uploadData.name" placeholder="Medienname"></b-form-input>
                </b-form-group>
                <b-form-group v-if="youtubeVideo">
                    <label for="youtubeUrl">YouTube URL</label>
                    <b-form-input type="text" id="youtubeUrl" v-model="uploadData.youtubeUrl" placeholder="Youtube URL"></b-form-input>
                </b-form-group>


                <b-form-group v-if="youtubeVideo || fileCollection[0].type.includes('video')">
                    <label for="bild">Posterbild</label><br>
                    <div class="margin-bottom-10">
                        <img v-if="uploadData.image && uploadData.image.id > 0" v-bind:src="$store.state.mediaServiceUrl + uploadData.image.uid + '/200/200'" class="image-placeholder-150">
                    </div>
                    <b-button size="sm" @click="openVideoMediaSelection('image')" variant="dark"><i class="fa"></i>Bild auswählen/ändern</b-button>&nbsp;
<!--                    <a class="fa fa-trash fa-lg text-primary" v-if="firma.image && firma.image.id" @click="deleteMedia('image')"></a>-->
<!--                    <b-form-input type="text" id="bild" v-model="uploadData.youtubeUrl" placeholder="Youtube URL"></b-form-input>-->
                    <MediaSelection
                            :show="showVideoMediaSelection"
                            :type="videoMediaType"
                            :mime-types="'image/jpeg,image/png'"
                            @close="showVideoMediaSelection = false"
                            @save="saveVideoMedia($event)"
                            :block="true"
                    />
                </b-form-group>

                <b-form-group>
                    <label for="mediaAlt">Alt</label>
                    <b-form-input type="text" id="mediaAlt" v-model="uploadData.alt" placeholder="Alt-Text"></b-form-input>
                </b-form-group>
                <b-form-group>
                    <label for="mediaTitle">Title</label>
                    <b-form-input type="text" id="mediaTitle" v-model="uploadData.title" placeholder="Title-Text"></b-form-input>
                </b-form-group>
                <b-form-group>
                    <label for="mediaCaption">Caption</label>
                    <b-form-input type="text" id="mediaCaption" v-model="uploadData.caption" placeholder="Caption"></b-form-input>
                </b-form-group>
                <b-row>
                    <b-col md="6">
                        <b-form-group>
                            <label for="backgroundPosX">Background-Position-X</label>
                            <b-form-input style="width: 80%; float: left;" type="number" id="backgroundPosX" v-model="uploadData.backgroundPosX" value="50" placeholder="Background-Position-X"></b-form-input>
                            <b-input-group-append style="float: left; margin-top: 3px;">
                                <b-input-group-text><i class="fa fa-percent"></i></b-input-group-text>
                            </b-input-group-append>
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group>
                            <label for="backgroundPosY">Background-Position-Y</label>
                            <b-form-input style="width:80%; float: left;" type="number" id="backgroundPosY" v-model="uploadData.backgroundPosY" value="50" placeholder="Background-Position-Y"></b-form-input>
                            <b-input-group-append style="float: left; margin-top: 3px;">
                                <b-input-group-text><i class="fa fa-percent"></i></b-input-group-text>
                            </b-input-group-append>
                        </b-form-group>
                    </b-col>
                </b-row>
            </div>
        </b-modal>
        <b-modal title="Ordner erstellen" v-model="createFolder" @ok="saveFolder()">
            <div>
                <b-form-group>
                    <label for="mediaName">Name</label>
                    <b-form-input type="text" id="mediaName" v-model="folderData.name" placeholder="Ordnername"></b-form-input>
                </b-form-group>
            </div>
        </b-modal>
    </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import FolderTree from "./FolderTree";
import VueNotifications from 'vue-notifications'
import MediaSelection from './MediaSelection'


const axios = require('axios')

export default {
    name: 'media',
    components: {FolderTree, MediaSelection},
    data () {
        return {
            warningModalDeleteFile: false,
            warningModalDeleteFolder: false,
            uploadModal: false,
            createFolder: false,
            mediaToDelete: 0,
            uploadData: {
                file: undefined,
                name: undefined,
                alt: undefined,
                title: undefined,
                caption: undefined,
                backgroundPosX: undefined,
                backgroundPosY: undefined,
            },
            fileCollection: [],
            folderData: {
                name: undefined
            },
            selectedFolder: {},
            mediaViewType: 'table',
            youtubeVideo: false,

            showVideoMediaSelection: false,
            videoMediaType: '',
            // mimeVideoType: '',
        }
    },
    computed: {
        ...mapGetters({
            media: 'media/getMedia',
            folders: 'media/getFolders',
            selected: 'media/getSelected',
            authObj: 'getAuthObj'
        }),
    },


    mounted() {
        this.$store.dispatch('media/setSelectedMedia', { media:  null })
        this.$store.dispatch('media/loadMedia', { folderId: this.selectedFolder.id}),
        this.$store.dispatch('media/loadFolders', { level: this.currentFolderLevel})
    },
    methods: {
        select: function(media) {
            this.$store.dispatch('media/setSelectedMedia', { media:  media })
        },
        deleteMedia() {
            axios.delete(this.$store.state.baseUrl + 'media/' + this.mediaToDelete, { headers: { 'X-AUTH-TOKEN': this.$store.state.authObj.token } })
                .then((response)=>{
                    this.$store.dispatch('media/loadMedia', { folderId: this.selectedFolder.id})
                    if(this.$store.state.media.selected.id == this.mediaToDelete){
                        this.$store.dispatch('media/setSelectedMedia', { media: null })
                    }
                }).catch(error => {
                    if(error.response.status == 403){
                        this.$store.commit('setAuthObj', { authObj: null }, { root: true })
                    } else {
                        // TODO: code me!
                    }
                });
        },
        deleteFolder(){
            if(this.media.length > 0 || this.selectedFolder.children.length > 0){
                this.notifyFolderNotEmpty()
            } else {
                let parentFolder = this.selectedFolder.parent
                axios.delete(this.$store.state.baseUrl + 'media-folder/' + this.selectedFolder.id, { headers: { 'X-AUTH-TOKEN': this.$store.state.authObj.token } })
                    .then((response)=>{
                        this.selectedFolder = parentFolder
                        this.$store.dispatch('media/loadFolders', { level: this.currentFolderLevel-1})
                        if(this.$parent.$options.name == 'FolderTree'){
                            this.$parent.selectFolder(parentFolder)
                        }

                    }).catch(error => {
                    if(error.response.status == 403){
                        this.$store.commit('setAuthObj', { authObj: null }, { root: true })
                    } else {
                        // TODO: code me!
                    }
                });
            }
        },
        openFileUploadDialog() {
            if(this.selectedFolder.id > 0){
                this.uploadModal = true,
                    this.uploadData = {
                        file: '',
                        name: '',
                        alt: '',
                        title: '',
                        caption: '',
                        backgroundPosX: 50,
                        backgroundPosY: 50,
                    }
                this.fileCollection = []
            } else {
                this.notifyNoFolderSelected()
            }
        },
        openCreateFolderDialog() {
            if(this.selectedFolder.id > 0){
                this.createFolder = true,
                    this.folderData = {
                        name: '',
                    }
            } else {
                this.notifyNoFolderSelected()
            }
        },
        fileInputSelected() {
            this.fileCollection = this.$refs.uploadFiles.files
            if(this.fileCollection.length == 1){
                if(this.uploadData.name == undefined || this.uploadData.name == '') {
                    this.uploadData.name = this.$refs.uploadFiles.files[0].name
                }
            } else {
                this.uploadMedia()
            }
        },

        addFiles(e){
            this.fileCollection = e.dataTransfer.files
            if(this.fileCollection.length == 1){
                if(this.uploadData.name == undefined || this.uploadData.name == '') {
                    this.uploadData.name = e.dataTransfer.files[0].name
                }
            } else {
                this.uploadMedia()
            }
        },

        uploadMedia() {
            for(let i = 0; i < this.fileCollection.length; i++){
                let formData = new FormData()
                formData.append('file', this.fileCollection[i])

                if(this.fileCollection.length == 1) {
                    formData.append('name', this.uploadData.name)
                    formData.append('alt', this.uploadData.alt)
                    formData.append('title', this.uploadData.title)
                    formData.append('caption', this.uploadData.caption)
                    formData.append('backgroundPosX', this.uploadData.backgroundPosX)
                    formData.append('backgroundPosY', this.uploadData.backgroundPosY)
                    formData.append('folderId', this.selectedFolder.id)
                    if(this.uploadData.image && this.uploadData.image.id) {
                        formData.append('image', this.uploadData.image.id)
                    }
                } else {
                    formData.append('name', this.fileCollection[i].name)
                    formData.append('folderId', this.selectedFolder.id)
                }

                axios.post(this.$store.state.baseUrl + 'media', formData, { headers: { 'Content-Type': 'multipart/form-data', 'X-AUTH-TOKEN': this.$store.state.authObj.token }})
                    .then((response) => {
                        this.$store.dispatch('media/loadMedia', { folderId: this.selectedFolder.id})
                    })
                    .catch(error => {
                        if(error.response.status == 403){
                            commit('setAuthObj', { authObj: null }, { root: true })
                        } else {
                            // TODO: code me!
                        }
                    })
            }
            if(this.youtubeVideo) {
                let formData = new FormData()

                formData.append('name', this.uploadData.name)
                formData.append('alt', this.uploadData.alt)
                formData.append('title', this.uploadData.title)
                formData.append('caption', this.uploadData.caption)
                formData.append('backgroundPosX', this.uploadData.backgroundPosX)
                formData.append('backgroundPosY', this.uploadData.backgroundPosY)
                formData.append('folderId', this.selectedFolder.id)
                formData.append('youtubeUrl', this.uploadData.youtubeUrl)
                if(this.uploadData.image && this.uploadData.image.id) {
                    formData.append('image', this.uploadData.image.id)
                }

                axios.post(this.$store.state.baseUrl + 'media', formData, { headers: { 'Content-Type': 'multipart/form-data', 'X-AUTH-TOKEN': this.$store.state.authObj.token }})
                    .then((response) => {
                        this.$store.dispatch('media/loadMedia', { folderId: this.selectedFolder.id})
                    })
                    .catch(error => {
                        if(error.response.status == 403){
                            commit('setAuthObj', { authObj: null }, { root: true })
                        } else {
                            // TODO: code me!
                        }
                    })
            }
            this.uploadModal = false
        },
        updateMedia() {
            let formData = new FormData()

            formData.append('name', this.$store.state.media.selected.name)
            formData.append('alt', this.$store.state.media.selected.alt)
            formData.append('title', this.$store.state.media.selected.title)
            formData.append('caption', this.$store.state.media.selected.caption)
            if(this.$store.state.media.selected.backgroundPosX != undefined){
                formData.append('backgroundPosX',this.$store.state.media.selected.backgroundPosX)
            } else {
                formData.append('backgroundPosX',0)
            }
            if(this.$store.state.media.selected.backgroundPosY != undefined){
                formData.append('backgroundPosY', this.$store.state.media.selected.backgroundPosY)
            } else {
                formData.append('backgroundPosY', 0)
            }


            axios.post(this.$store.state.baseUrl + 'media/' + this.$store.state.media.selected.id, formData, { headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'X-AUTH-TOKEN': this.$store.state.authObj.token }})
                .then((response) => {
                    this.$store.dispatch('media/loadMedia', { folderId: this.selectedFolder.id})
                })
                .catch(error => {
                    if(error.response.status == 403){
                        commit('setAuthObj', { authObj: null }, { root: true })
                    } else {
                        // TODO: code me!
                    }
                })
        },
        saveFolder() {
            this.$store.dispatch('media/saveFolder', {
                name: this.folderData.name,
                parent: this.selectedFolder.id
            }).then((response) => {
                this.$store.dispatch('media/loadFolders', { level: this.currentFolderLevel})
            })
        },
        updateFolder(){
            this.$store.dispatch('media/updateFolder', {
                id: this.selectedFolder.id,
                data: {
                    name: this.selectedFolder.name,
                    parent: this.selectedFolder.parent
                }
            }).then((response) => {
                this.$store.dispatch('media/loadFolders', { level: this.currentFolderLevel})
            })
        },
        folderSelection(folder) {
            this.selectedFolder = folder
            this.$store.dispatch('media/loadMedia', { folderId: this.selectedFolder.id})

            // if more than one root-level/folderTree-components call reset Selection on all other FolderTrees
            if(this.$refs[folder.id] != undefined){
                for (let refId in this.$refs) {
                    if (refId != folder.id) {
                        this.$refs[refId][0].resetSelection()
                    }
                }
            }
        },
        changeViewType(type){
            this.mediaViewType = type
        },
        openVideoMediaSelection: function(payload) {
            this.showVideoMediaSelection = true
            this.mediaVideoType = payload.mediaType
            // this.mimeVideoType = 'image/jpeg,image/png'
        },
        saveVideoMedia: function(payload){
            this.uploadData.image = {
                'id': payload.id,
                'uid': payload.uid
            }
        },
    },
    notifications: {
        notifyNoFolderSelected: {
            type: VueNotifications.types.error,
            title: 'Kein Ordner gewählt',
            message: 'Bitte wählen Sie zuerst einen Ordner'
        },
        notifyFolderNotEmpty: {
            type: VueNotifications.types.error,
            title: 'Löschen nicht möglich',
            message: 'Bitte löschen Sie zuerst alle Dateien und Unterordner'
        },
    }
}
</script>
